import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { PageLayout } from "./components/PageLayout";
import { THEME_NAME as THEME_NAME, getStyles } from "./services/theme";
import { Provider } from "react-redux";
import { Spinner } from "@vwfs-bronson/bronson-react";
import { store } from "./store/root.reducer";
import { routes } from "./routes";
import LandingPage from "./pages/LandingPage";
import PersonalDataPage from "./pages/PersonalDataPage";
import IbanCheckPage from "./pages/IbanCheckPage";
import SuccessPage from "./pages/SuccessPage";

const Init: React.FC = () => {
  /*const LandingPage = React.lazy(() => import("./pages/LandingPage"));
  const PersonalDataPage = React.lazy(() => import("./pages/PersonalDataPage"));
  const IbanCheckPage = React.lazy(() => import("./pages/IbanCheckPage"));
  const SuccessPage = React.lazy(() => import("./pages/SuccessPage"));*/
  /*const [isLoaded, setIsLoaded] = React.useState<boolean>(false);

  React.useEffect(() => {
    console.log(THEME_NAME)
    getStyles(THEME_NAME).then(() => {
      setIsLoaded(true);
    });
  }, []);*/

  return (
    <React.Suspense fallback={<Spinner fullPage />}>
      <Provider store={store}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <PageLayout>
            <Routes>
              <Route path={routes.landing_page} element={
                <React.Suspense fallback={<h1>LandingPage</h1>}><LandingPage /></React.Suspense>
                } />
              <Route
                path={routes.personal_data}
                element={
                  <React.Suspense fallback={<h1>PersonalDataPage</h1>}><PersonalDataPage /></React.Suspense>
                }
              />
              <Route path={routes.iban} element={
                <React.Suspense fallback={<h1>IbanCheckPage</h1>}><IbanCheckPage /></React.Suspense>
                } />
              <Route path={routes.success} element={
                <React.Suspense fallback={<h1>SuccessPage</h1>}><SuccessPage /></React.Suspense>
                } />
              <Route
                path="/index.html"
                element={
                  // TODO check where redirect should happen
                  <Navigate to={{ pathname: routes.landing_page }} replace />
                }
              />
              <Route
                path="*"
                element={<Navigate to={routes.landing_page} replace />}
              />
            </Routes>
          </PageLayout>
        </BrowserRouter>
      </Provider>
    </React.Suspense>
  );
};

const root = createRoot(document.getElementById("root")!);
root.render(<Init />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
