import {
  Button,
  ButtonContainer,
  Card,
  ContentSection,
  Fieldset,
  Layout,
} from "@vwfs-bronson/bronson-react";
import { Form, Formik, FormikProps } from "formik";
import { InputFormField } from "../components/bronson-extensions/Formfield";
import { routes } from "../routes";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getBankingDetail,
  getBankName,
} from "../store/selectors/bankingDetail.selector";
import { setBankName, setIban } from "../store/slices/bankingDetail.slice";
import { fetchIBANCheckStatus } from "../services/api";
import React from "react";
import { BankingDetailStore } from "../store/store.model";

const IbanCheckPage: React.FC = () => {
  const navigate = useNavigate();
  const IbanInitialState = useSelector(getBankingDetail);
  const dispatch = useDispatch();
  const [isValid, setIsValid] = React.useState(false);
  const formikRef = React.useRef<FormikProps<BankingDetailStore>>(null);
  const bankName = useSelector(getBankName);

  return (
    <ContentSection pageWrap>
      <Formik
        initialValues={IbanInitialState}
        validationSchema={ibanValidationSchema}
        onSubmit={async (values) => {
          dispatch(setIban(values.iban));
          if (bankName) {
            dispatch(setBankName(bankName));
          }
          return navigate(routes.success);
        }}
        innerRef={formikRef}
      >
        {({ errors, touched, values }) => (
          <Form role="form">
            <Fieldset>
              <Card element="article" title="Bank Details">
                <Fieldset.Row>
                  <Layout>
                    <Layout.Item default="1/2" s="1/1">
                      <InputFormField
                        labelText="Iban (Test Iban: DE37270200001513509669)"
                        id="iban"
                        name="iban"
                        value={values}
                        onChange={async (e) => {
                          const result = await fetchIBANCheckStatus(
                            e.target.value
                          )
                            .then((res) => {
                              const paymentData = res.customerData.paymentData;
                              if (!paymentData.isValid) {
                                formikRef.current?.setFieldError(
                                  "iban",
                                  "Invalid iban"
                                );
                                dispatch(setBankName(""));
                              }
                              if (paymentData.isValid && paymentData.bankName) {
                                dispatch(setBankName(paymentData.bankName));
                              }
                              return paymentData.isValid;
                            })
                            .catch(() => false);
                          setIsValid(result);
                        }}
                      />
                    </Layout.Item>
                    <Layout.Item>{bankName}</Layout.Item>
                  </Layout>
                </Fieldset.Row>
              </Card>
            </Fieldset>
            <ButtonContainer>
              <Button
                type="button"
                secondary
                onClick={() => navigate(routes.personal_data)}
              >
                Back
              </Button>
              <Button disabled={!isValid} type="submit">
                Submit
              </Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </ContentSection>
  );
};

export default IbanCheckPage;

const ibanRegex = RegExp(/^[A-Z]{2}(?:[ ]?[0-9]){18,20}$/);

const ibanValidationSchema = Yup.object().shape({
  iban: Yup.string()
    .required("Iban required")
    .test("iban", "Invalid iban", async (value) => {
      if (value?.length === 22 && ibanRegex.test(value)) {
        return true;
      }
      return false;
    }),
});
