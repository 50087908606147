import { Footer } from "@vwfs-bronson/bronson-react";
import React from "react";

export default function FooterComponent() {
    return (
        <Footer>
        <React.Fragment key=".0">
            <Footer.ModuleMetaNav
            copyrightText="Bronson with a year that needs to be updated every year – grateful task"
            socialItems={[
                {
                href: 'https://react.bronson.vwfs.tools',
                icon: 'semantic-social-facebook',
                label: 'Facebook',
                normalLink: true
                },
                {
                href: 'https://react.bronson.vwfs.tools',
                icon: 'semantic-social-instagram',
                label: 'Instagram',
                normalLink: true
                },
                {
                href: 'https://react.bronson.vwfs.tools',
                icon: 'semantic-social-youtube',
                label: 'YouTube',
                normalLink: true
                },
                {
                href: 'https://react.bronson.vwfs.tools',
                icon: 'semantic-social-x',
                label: 'X',
                normalLink: true
                },
                {
                href: 'https://react.bronson.vwfs.tools',
                icon: 'semantic-social-pinterest',
                label: 'Pinterest',
                normalLink: true
                }
            ]}
            >
            <Footer.ModuleMetaNav.Item href="#0">
                Lorem & ipsum
            </Footer.ModuleMetaNav.Item>
            <Footer.ModuleMetaNav.Item href="#0">
                Dolor
            </Footer.ModuleMetaNav.Item>
            <Footer.ModuleMetaNav.Item href="#0">
                Sit amet
            </Footer.ModuleMetaNav.Item>
            <Footer.ModuleMetaNav.Item href="#0">
                Consectetur
            </Footer.ModuleMetaNav.Item>
            <Footer.ModuleMetaNav.Item href="#0">
                Adipisicing elit
            </Footer.ModuleMetaNav.Item>
            <Footer.ModuleMetaNav.Item href="#0">
                Officiis voluptatum
            </Footer.ModuleMetaNav.Item>
            </Footer.ModuleMetaNav>
        </React.Fragment>
        </Footer>
    )
};