export enum ThemeType {
  audi = "audi",
  vw = "vw",
  vw6 = "vw6",
  seat = "seat",
  skoda = "skoda",
  cupra = "cupra",
}

export interface LogoSrc {
  logoDefault: string;
  logoSmall: string;
}

export const THEME_NAME = process.env.REACT_APP_WEBSITE_BRAND! as ThemeType;

export const isTheme = (theme: ThemeType | ThemeType[]) => {
  if (Array.isArray(theme)) {
    return theme.includes(THEME_NAME);
  }

  return theme === THEME_NAME;
};

const chooseTheme = (theme: ThemeType): string => {
  if ([ThemeType.vw6].includes(theme)) {
    return "vw6";
  }

  return theme;
};

export const getStyles = (theme = ThemeType.vw6) => {
  const bronsonTheme = chooseTheme(theme);

  return import(`@vwfs-bronson/bronson-${bronsonTheme}/dist/css/style.min.css`);
};

export const getLogosSrc = async (themeName: ThemeType): Promise<LogoSrc> => {
  let logoDefault;
  let logoSmall;

  switch (themeName) {
    case ThemeType.skoda:
    case ThemeType.vw:
      // logoDefault = await import(
      //   `@vwfs-bronson/bronson-${brandName}/dist/img/logo.png`
      // );
      // logoSmall = await import(
      //   `@vwfs-bronson/bronson-${brandName}/dist/img/logo-small-screen.png`
      // );

      // return {
      //   logoDefault: logoDefault.default,
      //   logoSmall: logoSmall.default,
      // };
    case ThemeType.seat:
    case ThemeType.audi:
    case ThemeType.cupra:
      logoDefault = await import(
        `@vwfs-bronson/bronson-${themeName}/dist/img/logo.svg`
      );

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoDefault.default,
      };

    default:
      logoDefault = await import(
        `@vwfs-bronson/bronson-${chooseTheme(themeName)}/dist/img/logo.png`
      );
      logoSmall = await import(
        `@vwfs-bronson/bronson-${chooseTheme(
          themeName
        )}/dist/img/logo-small-screen.png`
      );

      return {
        logoDefault: logoDefault.default,
        logoSmall: logoSmall.default,
      };
  }
};
