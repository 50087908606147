import { configureStore } from "@reduxjs/toolkit";
import personalDataReducer from "./slices/personalData.slice";
import bankingDetailReducer from "./slices/bankingDetail.slice";

export const store = configureStore({
  reducer: {
    personalData: personalDataReducer,
    bankingDetail: bankingDetailReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
