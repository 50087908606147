import { axios } from "./api.client";

export type CheckIbanResponse = {
  customerData: {
    paymentData: {
      bankName: string;
      iban: string;
      bic: string;
      isValid: boolean;
    };
  };
};

export const fetchIBANCheckStatus = async (iban: string) => {
  return axios
    .post<CheckIbanResponse>("/iban-service/validate", { iban })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};
