import {
  Button,
  ContentSection,
  DescriptionList,
  Heading,
  ProductTable,
} from "@vwfs-bronson/bronson-react";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes";
import { useSelector } from "react-redux";
import { getPersonalData } from "../store/selectors/personalData.selector";
import { getBankingDetail } from "../store/selectors/bankingDetail.selector";

const SuccessPage: React.FC = () => {
  const navigate = useNavigate();
  const personalData = useSelector(getPersonalData);
  const bankingDetail = useSelector(getBankingDetail);
  return (
    <>
      <ContentSection pageWrap>
        <Heading level={2}>Registration successful</Heading>
        <ProductTable.Section>
          <Heading level={5}>Personal Information</Heading>
          <DescriptionList horizontal>
            <DescriptionList.Group termText="First Name">
              <DescriptionList.Detail
                action={
                  <Button
                    type="button"
                    ariaLabel="Edit row entry"
                    icon="semantic-edit"
                    link
                    simple
                    onClick={() => navigate(routes.personal_data)}
                  ></Button>
                }
              >
                {personalData.firstName}
              </DescriptionList.Detail>
            </DescriptionList.Group>
            <DescriptionList.Group termText="Last Name">
              <DescriptionList.Detail>
                {personalData.lastName}
              </DescriptionList.Detail>
            </DescriptionList.Group>
            <DescriptionList.Group termText="Email">
              <DescriptionList.Detail>
                {personalData.email}
              </DescriptionList.Detail>
            </DescriptionList.Group>
          </DescriptionList>
        </ProductTable.Section>
        <ContentSection>
          <ProductTable.Section>
            <Heading level={5}>Bank Details</Heading>
            <DescriptionList horizontal>
              <DescriptionList.Group termText="Bank Institut">
                <DescriptionList.Detail>
                  {bankingDetail.bankName}
                </DescriptionList.Detail>
              </DescriptionList.Group>
              <DescriptionList.Group termText="Iban">
                <DescriptionList.Detail
                  action={
                    <Button
                      type="button"
                      ariaLabel="Edit row entry"
                      icon="semantic-edit"
                      link
                      simple
                      onClick={() => navigate(routes.iban)}
                    ></Button>
                  }
                >
                  {bankingDetail.iban}
                </DescriptionList.Detail>
              </DescriptionList.Group>
            </DescriptionList>
          </ProductTable.Section>
        </ContentSection>
        <ContentSection>
          <Button onClick={() => navigate(routes.iban)}>Back</Button>
        </ContentSection>
      </ContentSection>
    </>
  );
};
export default SuccessPage;
const shouldUseInvertedHeroImage =
  process.env.REACT_APP_WEBSITE_BRAND !== "cupra";
