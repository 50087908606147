import React from "react";
import { HeroTeaser } from "@vwfs-bronson/bronson-react";
import { THEME_NAME, ThemeType, isTheme } from "../services/theme";
import { useNavigate } from "react-router-dom";
import { routes } from "../routes";

const LandingPage: React.FC = () => {
  const navigate = useNavigate();

  const handleMoveToNextPersonalDataForm = () => {
    navigate(routes.personal_data);
  };

  return (
    <>
      <HeroTeaser
        buttonText="Configure now"
        onButtonClick={handleMoveToNextPersonalDataForm}
        imgProps={{
          sizes: "100vw",
          alt: "landingPage.heroTitle",
          ...(isTheme([ThemeType.cupra])
            ? {
                src: getHeroImageSrc(),
              }
            : {
                srcSet: `${process.env.PUBLIC_URL}/assets/img/hero/${THEME_NAME}/img-source-set__480x270.jpg 480w,
                  ${process.env.PUBLIC_URL}/assets/img/hero/${THEME_NAME}/img-source-set__720x405.jpg 720w,
                  ${process.env.PUBLIC_URL}/assets/img/hero/${THEME_NAME}/img-source-set__960x540.jpg 960w,
                  ${process.env.PUBLIC_URL}/assets/img/hero/${THEME_NAME}/img-source-set__1280x720.jpg 1280w,
                  ${process.env.PUBLIC_URL}/assets/img/hero/${THEME_NAME}/img-source-set__1920x1080.jpg 1920w`,
              }),
        }}
        inverted={shouldUseInvertedHeroImage}
        shallow
        sub="Lorem ipsum dolor sit amet, consectetur adipisicing elit. DolThis orem ullam, sed accusantium quia."
        title={[<>IT Standards</>, <>Sample Frontend</>]}
      />
    </>
  );
};

const getHeroImageSrc = () => {
  if (isTheme(ThemeType.cupra)) {
    return `${process.env.PUBLIC_URL}/assets/img/hero/${THEME_NAME}/hero-image-cupra.jpg`;
  }
};

export type LandingPageFormData = {
  firstName: string;
  lastName: string;
};

export default LandingPage;

const shouldUseInvertedHeroImage =
  process.env.REACT_APP_WEBSITE_BRAND !== "cupra";
