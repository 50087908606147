import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BankingDetailStore } from "../store.model";

const bankingDetailState: BankingDetailStore = {
  iban: "",
  bankName: "",
  bic: "",
  isValid: false,
};

const bankingDetailSlice = createSlice({
  name: "bankingDetail",
  initialState: bankingDetailState,
  reducers: {
    setIban: (state, action: PayloadAction<string>) => ({
      ...state,
      iban: action.payload,
    }),
    setBankName: (state, action: PayloadAction<string>) => ({
      ...state,
      bankName: action.payload,
    }),
    setBic: (state, action: PayloadAction<string>) => ({
      ...state,
      bic: action.payload,
    }),
    setIsValid: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isValid: action.payload,
    }),
  },
});

export const {
  setIban,
  setBankName,
  setBic,
  setIsValid,
} = bankingDetailSlice.actions;

export default bankingDetailSlice.reducer;
