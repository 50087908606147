import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PersonalDataStore } from "../store.model";

const personalDataInitialState: PersonalDataStore = {
  dateOfBirth: "",
  lastName: "",
  firstName: "",
  email: "",
};

const personalDataSlice = createSlice({
  name: "personalData",
  initialState: personalDataInitialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => ({
      ...state,
      firstName: action.payload,
    }),
    setLastName: (state, action: PayloadAction<string>) => ({
      ...state,
      lastName: action.payload,
    }),
    setEmail: (state, action: PayloadAction<string>) => ({
      ...state,
      email: action.payload,
    }),
    setDateOfBirth: (state, action: PayloadAction<string>) => ({
      ...state,
      dateOfBirth: action.payload,
    }),
  },
});

export const {
  setFirstName,
  setLastName,
  setEmail,
  setDateOfBirth,
} = personalDataSlice.actions;

export default personalDataSlice.reducer;
