import {
  ErrorMessage,
  FormField as BronsonFormField,
  InfoIcon,
  Input,
  DatePicker,
} from "@vwfs-bronson/bronson-react";
import { Field, useFormikContext } from "formik";
import  get  from "lodash/get";
import isString from "lodash/isString"
import { v4 as uuid } from "uuid";

export const FormField: React.FC<BaseFormFieldProps> = ({
  render,
  name,
  labelText,
  type,
  error = "",
  infoIconText,
  className,
  labelElement,
  testId,
  id,
}) => {
  return (
    <Field name={name}>
      {({ form, field }: { form: any; field: any }) => {
        const errorValue = error || get(form.errors, name);
        return (
          <BronsonFormField
            id={id ?? name}
            className={className}
            labelElement={labelElement || "label"}
            labelText={labelText}
            type={type || "input"}
            errorMessage={
              (error || get(form.errors, name)) && get(form.touched, name) ? (
                <ErrorMessage
                  id={`error-${uuid()}`}
                  dangerouslySetInnerHTML={{
                    __html: isString(errorValue) ? errorValue : errorValue.key,
                  }}
                />
              ) : (
                ""
              )
            }
            infoIcon={
              infoIconText ? (
                <InfoIcon icon="semantic-info">
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: infoIconText }} />
                </InfoIcon>
              ) : null
            }
          >
            {render({
              name,
              testId,
              id: `input-${id}`,
              error: !!(form.touched[name!] && (error || form.errors[name!])),
              ...field,
            })}
          </BronsonFormField>
        );
      }}
    </Field>
  );
};

export const InputFormField: React.FC<InputFieldProps> = ({
  name,
  labelText,
  infoIconText,
  disabled = false,
  onInput,
  onBlur,
  onChange,
  serverError = "",
  addon,
  testId,
  id,
  value,
}) => (
  <FormField
    id={id}
    name={name}
    error={serverError}
    labelText={labelText}
    infoIconText={infoIconText}
    type="input"
    value={value}
    render={(fieldProps: any) => (
      <Input
        {...fieldProps}
        {...(addon ? { addonElement: "button", addonText: addon } : {})}
        onInput={onInput}
        disabled={disabled}
        placeholder={""}
        testId={testId ?? `input-${name}`}
        id={`input-${id}`}
        onChange={(e) => {
          fieldProps.onChange(e);
          if(onChange) onChange(e);
        }}
        onBlur={(e) => {
          fieldProps.onBlur(e);
          if(onBlur) onBlur(e)
        }}
      />
    )}
  />
);

export const DatePickerFormField: React.FC<DatePickerFieldProps> = ({
  id,
  name,
  minDate,
  maxDate,
  outputDateFormat,
  excludeWeekend,
  disableMobile,
  serverError = "",
  labelText,
  infoIconText,
  value,
  placeholder,
  customDisableDateConditions = [],
  disabled,
  maskedInput = false,
}) => {
  const { setFieldTouched, setFieldValue, getFieldMeta } = useFormikContext();
  const disabledDateConditions = [...customDisableDateConditions];
  if (excludeWeekend)
    disabledDateConditions.push(
      (date: Date) => date.getDay() === 0 || date.getDay() === 6
    );
  return (
    <FormField
      id={id}
      name={name}
      error={serverError}
      labelText={labelText}
      infoIconText={infoIconText}
      type="input"
      value={value}
      render={(fieldProps: any) => (
        <DatePicker
          {...fieldProps}
          id={id}
          name={name}
          placeholder={placeholder}
          dateFormat={outputDateFormat}
          flatpickrOptions={{
            disable: disabledDateConditions,
            disableMobile: !!disableMobile,
            errorHandler: (e: unknown) => {
              console.log(`Flatpickr error: ${e}`);
            },
          }}
          maxDate={maxDate}
          minDate={minDate}
          onBlur={(): void => {
            setFieldTouched(name, true, true);
          }}
          onChange={(value: string): void => {
            setFieldValue(name, value, getFieldMeta(name).touched);
          }}
          disabled={disabled}
          maskedInput={maskedInput}
        />
      )}
    />
  );
};

export type BaseFormFieldProps = {
  className?: string;
  labelText?: string;
  infoIconText?: string;
  disabled?: boolean;
  value: any;
  name: string;
  id?: string;
  type?: "other" | "input" | "select" | "textarea" | "checkbox";
  error?: string;
  render?: any;
  labelElement?: string;
  testId?: string;
};

export type InputFieldProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  maxLength?: number;
  placeholder?: string;
  serverError?: string;
  addon?: string;
} & BaseFormFieldProps;

export type DatePickerFieldProps = {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  id: string;
  name: string;
  label: string;
  onLinkClick?: Function;
  type?: "text";
  testId?: string;
  language?: string;
  minDate?: string;
  maxDate?: string;
  outputDateFormat: string;
  excludeWeekend?: boolean;
  disableMobile?: boolean;
  tooltip?: string;
  isMandatory?: boolean;
  placeholder?: string;
  onFocus?: Function;
  customDisableDateConditions?: ((date: Date) => boolean)[];
  disabled?: boolean;
  hint?: string | React.ReactNode;
  maskedInput?: boolean;
  serverError?: string;
} & BaseFormFieldProps;
