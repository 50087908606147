import React from 'react';
import { Header, Logo } from '@vwfs-bronson/bronson-react';
import { THEME_NAME, ThemeType } from '../services/theme';
import Footer from '../pages/Footer';
import { useNavigate } from 'react-router-dom';
import { routes } from '../routes';

export const PageLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Header
        staticHeader={ThemeType.vw !== THEME_NAME}
        logo={
          (
            <Logo onClick={() => navigate(routes.landing_page)}/>
          )
        }
      />
      {children}
      <Footer />
    </>
  );
};
