import {
  Fieldset,
  Layout,
  Button,
  ContentSection,
  Card,
  ButtonContainer,
} from "@vwfs-bronson/bronson-react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  DatePickerFormField,
  InputFormField,
} from "../components/bronson-extensions/Formfield";
import moment from "moment";
import { routes } from "../routes";
import { useNavigate } from "react-router-dom";
import { getPersonalData } from "../store/selectors/personalData.selector";
import { useDispatch, useSelector } from "react-redux";
import {
  setDateOfBirth,
  setEmail,
  setFirstName,
  setLastName,
} from "../store/slices/personalData.slice";

export default function PersonalDataPage() {
  const navigate = useNavigate();
  const personalDataInitialState = useSelector(getPersonalData);
  const dispatch = useDispatch();
  return (
    <ContentSection pageWrap>
      <Formik
        initialValues={personalDataInitialState}
        validationSchema={personalDataValidationSchema}
        onSubmit={(values) => {
          dispatch(setFirstName(values.firstName));
          dispatch(setLastName(values.lastName));
          dispatch(setEmail(values.email));
          dispatch(setDateOfBirth(values.dateOfBirth));
          return navigate(routes.iban);
        }}
      >
        {({ errors, touched, values }) => (
          <Form role="form">
            <Fieldset>
              <Card element="article" title="Personal Information">
                <Fieldset.Row>
                  <Layout>
                    <Layout.Item default="1/2" s="1/1">
                      <InputFormField
                        labelText="First Name"
                        id="firstName"
                        name="firstName"
                        value={values}
                      />
                    </Layout.Item>
                    <Layout.Item default="1/2" s="1/1">
                      <InputFormField
                        labelText="Last Name"
                        id="lastName"
                        name="lastName"
                        value={values}
                      />
                    </Layout.Item>
                  </Layout>
                </Fieldset.Row>
                <Fieldset.Row>
                  <Layout>
                    <Layout.Item default="1/2" s="1/1">
                      <InputFormField
                        labelText="Email"
                        id="email"
                        name="email"
                        value={values}
                      />
                    </Layout.Item>
                    <Layout.Item default="1/2" s="1/1">
                      <DatePickerFormField
                        labelText="Date of Birth"
                        id="dateOfBirth"
                        name="dateOfBirth"
                        value={values}
                        label={"Date of birth"}
                        outputDateFormat={"d.m.Y"}
                      />
                    </Layout.Item>
                  </Layout>
                </Fieldset.Row>
              </Card>
            </Fieldset>
            <ButtonContainer>
              <Button
                type="button"
                secondary
                onClick={() => navigate(routes.landing_page)}
              >
                Back
              </Button>
              <Button type="submit">Next</Button>
            </ButtonContainer>
          </Form>
        )}
      </Formik>
    </ContentSection>
  );
}

const emailValidation = Yup.string()
  .required("Required")
  .email("Invalid email address")
  .min(6, "Invalid email address")
  .matches(
    RegExp(
      /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/
    ),
    "Invalid email address"
  );

const personalDataValidationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: emailValidation,
  dateOfBirth: Yup.string()
    .test("dateOfBirth", "You must be 18 years or older", function (value) {
      return moment().diff(moment(value, "DD-MM-YYYY"), "years") >= 18;
    })
    .required("Please enter your age"),
});
