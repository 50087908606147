import _axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_SERVER_URL;

export const axios = _axios.create({
  baseURL: apiBaseUrl,
});

axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    return Promise.reject(err);
  }
);
